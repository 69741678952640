import React from "react"
import { graphql } from "gatsby"
import IndexPage from "../components/IndexPage"

export default props => <IndexPage {...props} lang="en" />

export const pageQuery = graphql`
  query {
    allContentfulHomePage(filter: { node_locale: { eq: "en-US" } }) {
      pageContent: edges {
        node {
          id
          pageName
          headText
          homeProjects {
            id
            name
            year
            jobTitle
            projectUrl
            company {
              id
              name
            }
            content {
              id
              json
            }
            illustration {
              id
              fluid {
                src
                srcSet
                srcWebp
                srcSetWebp
              }
              title
            }
            medias {
              description
              title
              fluid {
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
    allContentfulAboutPage(filter: { node_locale: { eq: "en-US" } }) {
      pageContent: edges {
        node {
          id
          pageName
          paragraphMe {
            id
            paragraphMe
          }
          paragraphCompanies {
            id
            paragraphCompanies
          }
          paragraphTechno {
            id
            paragraphTechno
          }
          jobExperiences {
            name
            location
            jobDuration
            jobTitle
            url
          }
        }
      }
    }
  }
`
